import classNames from 'classnames/bind';
import { graphql, Link, PageProps } from 'gatsby';
import React from 'react';
import { Intro } from '../components/Intro';
import { Layout } from '../components/Layout/index';
import SEO from '../components/SEO/SEO';
import * as styles from './changelog.module.scss';

const cx = classNames.bind(styles);

const ChangelogPage: React.FC<PageProps<any>> = ({ data }) => (
  <Layout>
    <SEO title="Changelog" />

    <Intro>
      <h2>Changelog</h2>
      <h3>Recent updates and improvements in Sendeo</h3>
    </Intro>

    <div className="container">
      <div className="content">
        <div className="grid">
          {data.allMarkdownRemark.edges.map(({ node: { html, frontmatter: { date, formattedDate } } }: any) => (
            <div className={cx('changelog-item')}>
              <div className="col-12" key={date}>
                <div className="grid">
                  <div className="col-4_sm-12">
                    <Link className={cx('date')} to={`/changelog/${date}/`}>
                      {formattedDate}
                    </Link>
                  </div>
                  <div className="col-8_sm-12">
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Layout>
);

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(changelog)/.*\\.md$/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          html
          frontmatter {
            date
            formattedDate: date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;

export default ChangelogPage;
