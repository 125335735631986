import classNames from 'classnames/bind';
import React from 'react';
import styles from './Intro.module.scss';

const cx = classNames.bind(styles);

const Intro: React.FC = ({ children }) => {
  return <div className={cx('intro')}>{children}</div>;
};

export default Intro;
